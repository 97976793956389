<template>
  <v-app-bar
    class="nav-border"
    id="app-bar"
    color="#eeeeee"
    app
    flat
    height="65"
  >
    <v-btn
      color="#d9242a"
      class="mr-3"
      elevation="1"
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value" color="#fff">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else color="#fff">
        mdi-menu
      </v-icon>
      <span class="ml-2">Menú</span>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />
    
    <div class="mx-3" />
    <span style="color:#d9242a" class="mr-2">{{user.nombre +"  "+ user.ap_paterno +"  "+ user.ap_materno}}</span>
        <v-menu offset-y
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#d9242a"
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="#fff">mdi-account-cog</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/panel/pages/user">
            <v-list-item-title>
              <v-icon class="mr-2">mdi-cog</v-icon>
              Configuraciones
            </v-list-item-title>
          </v-list-item>
           <v-divider class="mb-1" />
          <v-list-item @click="logout">
            <v-list-item-title>
              <v-icon class="red-txt mr-2">mdi-account-arrow-right</v-icon>
              Salir
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],

      items: [
        { title: 'Configuraciones',icon: 'mdi-cog', },
        { title: 'Salir',icon: 'mdi-account-arrow-right', },
      ],
    }),

    computed: {
      ...mapState(['drawer','user']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      async logout(){

        let token = localStorage.getItem('token');
        let form = new FormData();
        form.append("token",token.split(" ")[1]);
        await this.$http.post("logout",form);
        localStorage.removeItem('token');

        this.$store.commit("setAutenticated",false);
        this.$router.push({name:"Login"});
      }
      
    },
  }
</script>
